import defaultTheme from '@bluheadless/ui/src/theme/default'
import { createTheme as createMuiTheme } from '@mui/material/styles' // keep @mui/material/styles to use default theme of @mui/material
import { createTheme } from '@mui/system'
import BHCrossSell from './theme/components/bh-crosssell'
import BHHeader from './theme/components/bh-header'
import BHRecentlyViewed from './theme/components/bh-recently-viewed'
import BHRelated from './theme/components/bh-related'
import BHTileBase from './theme/components/bh-tile-base'
import BHUpsells from './theme/components/bh-upsells'
import Button, { buttonFontSize } from './theme/components/button'
import Card from './theme/components/card'
import CssBaseline from './theme/components/css-baseline'
import IconButton from './theme/components/icon-button'
import { fonts, fontSize, htmlFontSize } from './theme/fonts'

const muiTheme = createMuiTheme({ typography: { htmlFontSize, fontSize } })

const fontFamily = fonts.redHatDisplay
const fontFamilyAlternative = fonts.playfairDisplay

const theme = createTheme(defaultTheme, {
	palette: {
		common: {
			black: '#141616',
			white: '#fff',
			cream: '#F8F2E4',
			olive: '#3C372A',
		},
		footer: {
			bgMiddleTop: '#F8F2E4',
			bgMiddleBottom: '#F8F2E4',
			bgBottom: '#3C372A',
			color: '#141616',
			border: '#97948D',
		},
		secondary: {
			main: '#F8F2E4',
			light: '#669EFF',
			dark: '#024ED0',
			contrastText: '#141616',
		},
		text: {
			primary: '#000000',
			secondary: '#F8F2E4',
			disabled: '#fafafa',
		},
	},
	typography: {
		fontFamily,
		fontFamilyAlternative,
		htmlFontSize,
		fontSize,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h1: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: 1,
			letterSpacing: '1.25px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		h2: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(22),
			lineHeight: 1.185,
			letterSpacing: '0.2',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(32),
			},
		},
		h3: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(20),
			lineHeight: 1.167,
			letterSpacing: '0.2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(28),
			},
		},
		h4: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(18),
			lineHeight: 1.235,
			letterSpacing: '0.3px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(24),
			},
		},
		h5: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: 1.2,
			letterSpacing: '0.3px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(20),
			},
		},
		h6: {
			fontFamily: fontFamily,
			fontWeight: 800,
			fontSize: muiTheme.typography.pxToRem(18),
			lineHeight: '23px',
			letterSpacing: '0.2px',
		},
		body1: {
			fontFamily: fontFamily,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		body2: {
			fontFamily: fontFamily,
			fontWeight: 600,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		p: {
			fontFamily: fontFamily,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		subtitle1: {
			fontFamily: fontFamily,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		subtitle2: {
			fontFamily: fontFamily,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		button: {
			fontFamily: fontFamily,
			fontWeight: 400,
			fontSize: buttonFontSize,
			lineHeight: 1.3,
			letterSpacing: '0.2px',
			borderRadius: 30,
		},
		caption: {
			fontFamily: fontFamily,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 1.4,
			letterSpacing: '0.2px',
		},
		overline: {
			fontFamily: fontFamily,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 2.66,
			letterSpacing: '0.2px',
			textTransform: 'uppercase',
		},
		headlinebig: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: 1,
			letterSpacing: '1.25px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		headlinemedium: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(32),
			lineHeight: 1,
			letterSpacing: '1.25px',
		},
		headlinesmall: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: 1,
			letterSpacing: '1.25px',
		},
		subheadline1: {
			fontFamily: fontFamily,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.2,
			letterSpacing: '1px',
		},
		subheadline2: {
			fontFamily: fontFamily,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 1.2,
			letterSpacing: '1px',
		},
		checkbox: {
			fontFamily: fontFamily,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: '16px',
			letterSpacing: '0.2px',
		},
	},
	components: {
		...BHCrossSell,
		...BHHeader,
		...BHRecentlyViewed,
		...BHRelated,
		...BHTileBase,
		...BHUpsells,
		...Button,
		...Card,
		...CssBaseline,
		...IconButton,
	},
})

export default theme
